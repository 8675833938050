import React from "react"

import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import HeaderImg from "../img/about.jpg"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  divide: {
    margin: theme.spacing(1),
  },
}))

const About = () => {
  const classes = useStyles()
  return (
    <Layout>
      <SEO title="关于 About" />
      <img src={HeaderImg} alt="East West Culture and Art Center" />
      <Typography
        variant="caption"
        display="block"
        gutterBottom
        className="caption"
      >
        2016年8月30日青城山上师在文化中心讲授茶道
      </Typography>
      <Divider className={classes.divide} />
      <Typography variant="h4" gutterBottom>
        关于我们 About us
      </Typography>
      <Typography paragraph>
        东西方文化艺术中心是一个不设目标、没有追求的闲散场所！有生意不拒绝，没买卖不强求。来者都是客，喝茶聊天、轻歌曼舞！
      </Typography>
      <Typography paragraph>
        这里有巴西教练长年开办的武术柔道初级、中级、高级班；
        有民乐演奏家每周固定的二胡演奏课；
        有人来互助学跳广场舞；有“群”自发练习小合唱；
        时不时有金融家做个投资讲座；动不动请文豪们来个读书交流；
        冷不丁呢，还打打麻将、下下棋，玩玩扑克、聚聚餐！
      </Typography>
      <Typography paragraph>
        总之， 这里洋溢着轻松快乐！ 这里充满着幸福祥和！
        这里，交融着东西方文化艺术!
      </Typography>
    </Layout>
  )
}

export default About
